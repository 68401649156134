import React from 'react'
import './Home.css'
import PageWrapper from 'UI/PageWrapper'
import UserTopBar from 'UI/UserTopBar'
import Button from 'UI/Button'
import Icon from 'UI/Icon'
type Props = {}

const Home = (props: Props) => {
  return (
    <PageWrapper className='home-page'>
        <UserTopBar/>
        <div style={{margin:8,display:'flex',justifyContent:'end'}}>
        <Button className=" glass btn-sl"><Icon icon='settings'/></Button>
        </div>
        <div className='home-actions'>
          <Button className=" glass btn-sl"><Icon icon='tuners'/></Button>
          <Button className=" glass btn-sl"><Icon icon='heart'/></Button>
        </div>
    </PageWrapper>
  )
}

export default Home