import React, { useEffect, useState } from 'react'
import logo from 'assets/images/logo.png'
import PageWrapper from 'UI/PageWrapper'
import './Loading.css'
import { useNavigate } from 'react-router-dom'
type Props = {}

const Loading = (props: Props) => {
  const [progress, setprogress] = useState(0)
  const nav = useNavigate()
  useEffect(() => {
    setTimeout(() => {
      if(progress === 100) {
        nav('/entry',{replace:true})
        return
      }
      setprogress((prev) => prev+1)
    }, 20);
  }, [progress])
  return (
    <PageWrapper className='loading-page'>
      <img src={logo} alt="" />
      <p className='glow'>{progress}%</p>
    </PageWrapper>
  )
}

export default Loading