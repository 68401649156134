import React, { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    icon:keyof typeof icons
}

const Icon = (props: Props) => {
  const icon = icons[props.icon]
//   icon.props = props
return icon
}

const icons = {
    loading:(
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 128 128" ><g><path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" fill="#ffffff"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1000ms" repeatCount="indefinite"></animateTransform></g></svg>
    ),
    navigationMining:(
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={24}
        fill="none"
      >
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M15.381 5.186C13.96 4.314 10.308 2.64 7.02 3.068c1.99 1.37 3.036 2.107 5.86 4.62m6.435 1.43c.872 1.423 2.544 5.074 2.117 8.362-1.37-1.989-2.106-3.036-4.62-5.859m-5.838-.203-7.05 7.05c-.572.571-.563 1.507.02 2.09.582.582 1.518.59 2.09.019l7.049-7.05m-.596-4.301 2.788 2.787c.31.31.81.31 1.119.003l3.453-3.454a.79.79 0 0 0-.002-1.118l-2.788-2.788a.79.79 0 0 0-1.118-.003l-3.454 3.454a.79.79 0 0 0 .002 1.119Z"
        />
      </svg>
    ),
    navigationEarn:(
      <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none">
      <path
        stroke="#fff"
        strokeWidth={1.5}
        d="M15.5 15a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3.5 12V6c2.105.621 6.576 1.427 12.004 1.803 2.921.202 4.382.303 5.189 1.174.807.87.807 2.273.807 5.078v2.013c0 2.889 0 4.333-.984 5.232-.983.899-2.324.768-5.005.506a61.504 61.504 0 0 1-2.011-.23"
      />
      <path
        stroke="#fff"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.126 8c.377-1.423.72-4.012-.299-5.297-.645-.815-1.605-.736-2.545-.654-4.944.435-8.437 1.318-10.389 1.918C4.053 4.225 3.5 5.045 3.5 5.96"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M11.5 18h-4m0 0h-4m4 0v4m0-4v-4"
      />
    </svg>
    ),
    navigationServices:(
      <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none">
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M19.917 15.713c3.951-5.01 2.884-9.978.046-11.719-2.682-1.645-5.023-.982-6.429.074l-1.034.774m7.417 10.871c-.948 1.203-2.185 2.407-3.757 3.57C14.614 20.428 13.841 21 12.5 21c-1.341 0-2.114-.572-3.66-1.717C.722 13.275 1.518 6.153 5.037 3.994c2.682-1.645 5.023-.982 6.429.074l1.034.774m7.417 10.871-5.525-6.268a.7.7 0 0 0-.895-.13l-2.186 1.366a1.965 1.965 0 0 1-2.233-3.23l3.422-2.61"
        />
      </svg>
    ),
    navigationFriends:(
      <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none">
      <g stroke="#fff" strokeWidth={1.5} clipPath="url(#a)">
        <path
          strokeLinecap="round"
          d="M21.274 18c.75 0 1.345-.471 1.88-1.13 1.096-1.35-.703-2.43-1.389-2.957-.697-.537-1.476-.842-2.265-.913m-1-2a2.5 2.5 0 0 0 0-5M3.726 18c-.75 0-1.345-.471-1.88-1.13-1.096-1.35.703-2.43 1.389-2.957.697-.537 1.476-.842 2.265-.913m.5-2a2.5 2.5 0 0 1 0-5"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.584 15.111c-1.022.632-3.701 1.922-2.07 3.536C7.313 19.436 8.2 20 9.316 20h6.37c1.115 0 2.003-.564 2.8-1.353 1.632-1.614-1.047-2.904-2.069-3.536a7.457 7.457 0 0 0-7.832 0Z"
        />
        <path d="M16 7.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5 0h24v24H.5z" />
        </clipPath>
      </defs>
    </svg>
    ),
    navigationProfile:(
      <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.283 17.111c-1.1.632-3.986 1.922-2.229 3.536C6.913 21.436 7.87 22 9.071 22h6.858c1.202 0 2.158-.564 3.017-1.353 1.757-1.614-1.128-2.904-2.229-3.536-2.58-1.481-5.854-1.481-8.434 0Z"
      />
      <path
        stroke="#fff"
        strokeWidth={1.5}
        d="M16 10a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M3.354 16a9.9 9.9 0 0 1-.854-4.035C2.5 6.46 6.977 2 12.5 2s10 4.461 10 9.965A9.9 9.9 0 0 1 21.646 16"
      />
    </svg>
    ),
    ton:(
      <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none">
        <path
          fill="#fff"
          d="m22.29 6.688-8.717 13.883a1.176 1.176 0 0 1-1.994-.006L3.032 6.682a2.46 2.46 0 0 1-.365-1.294 2.547 2.547 0 0 1 2.582-2.506h14.848c1.417 0 2.57 1.118 2.57 2.5 0 .46-.13.912-.377 1.306M5.13 6.224l6.36 9.805V5.235H5.795c-.658 0-.953.436-.664.989m8.712 9.805 6.36-9.805c.293-.553-.007-.989-.665-.989h-5.695v10.794Z"
        />
      </svg>
    ),
    messages:(
      <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none">
    <path
      stroke="#fff"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M22.5 11.667c0 5.283-4.478 9.566-10 9.566-.65.001-1.297-.059-1.935-.178-.459-.087-.688-.13-.848-.105-.16.024-.388.145-.842.386a6.5 6.5 0 0 1-4.224.657 5.292 5.292 0 0 0 1.087-2.348c.1-.53-.148-1.045-.52-1.422C3.534 16.512 2.5 14.205 2.5 11.667c0-5.283 4.478-9.567 10-9.567s10 4.284 10 9.567Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12.495 12.1h.01m3.986 0h.009m-8 0h.009"
    />
  </svg>
    ),
    settings:(
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      stroke="#fff"
      strokeWidth={1.5}
      d="M15.5 12.31a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M21.011 14.407c.522-.14.783-.21.886-.345.103-.135.103-.351.103-.784v-1.934c0-.433 0-.65-.103-.784s-.364-.205-.886-.346c-1.95-.526-3.171-2.565-2.668-4.502.139-.533.208-.8.142-.956-.066-.156-.256-.264-.635-.48l-1.725-.978c-.372-.212-.558-.317-.725-.295-.167.023-.356.21-.733.587-1.459 1.455-3.873 1.455-5.333 0-.377-.377-.565-.565-.732-.587-.167-.023-.353.083-.725.294l-1.725.98c-.38.215-.57.323-.635.479-.066.156.003.423.141.956.503 1.937-.718 3.976-2.669 4.502-.522.141-.783.212-.886.346-.103.135-.103.351-.103.784v1.934c0 .433 0 .65.103.784s.364.205.886.345c1.95.526 3.171 2.566 2.668 4.503-.139.533-.208.8-.142.956.066.156.256.264.635.479l1.725.98c.372.21.558.316.725.294.167-.023.356-.21.733-.587 1.46-1.457 3.876-1.457 5.336 0 .377.376.565.564.732.587.167.022.353-.084.726-.295l1.724-.98c.38-.214.57-.322.635-.478.066-.157-.003-.423-.141-.956-.504-1.938.716-3.976 2.666-4.503Z"
    />
  </svg>
    ),
    heart:(
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      fill="#fff"
      d="M19.463 3.994c-2.682-1.645-5.023-.982-6.429.074-.576.433-.864.65-1.034.65-.17 0-.458-.217-1.034-.65-1.406-1.056-3.747-1.719-6.429-.074-3.519 2.159-4.315 9.28 3.803 15.29C9.886 20.427 10.659 21 12 21c1.341 0 2.114-.572 3.66-1.717 8.118-6.008 7.322-13.13 3.803-15.289Z"
    />
  </svg>
    ),
    tuners:(
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7 21v-3M17 21v-6M17 6V3M7 9V3"
      />
      <path
        stroke="#fff"
        strokeWidth={1.5}
        d="M7 18c-.932 0-1.398 0-1.765-.152a2 2 0 0 1-1.083-1.083C4 16.398 4 15.932 4 15c0-.932 0-1.398.152-1.765a2 2 0 0 1 1.083-1.083C5.602 12 6.068 12 7 12c.932 0 1.398 0 1.765.152a2 2 0 0 1 1.083 1.083C10 13.602 10 14.068 10 15c0 .932 0 1.398-.152 1.765a2 2 0 0 1-1.083 1.083C8.398 18 7.932 18 7 18ZM17 12c-.932 0-1.398 0-1.765-.152a2 2 0 0 1-1.083-1.083C14 10.398 14 9.932 14 9c0-.932 0-1.398.152-1.765a2 2 0 0 1 1.083-1.083C15.602 6 16.068 6 17 6c.932 0 1.398 0 1.765.152a2 2 0 0 1 1.083 1.083C20 7.602 20 8.068 20 9c0 .932 0 1.398-.152 1.765a2 2 0 0 1-1.083 1.083C18.398 12 17.932 12 17 12Z"
      />
    </svg>
    ),
    info:(
      <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
  >
    <g stroke="#fff" clipPath="url(#a)">
      <path
        strokeWidth={1.5}
        d="M18.333 10a8.333 8.333 0 1 0-16.667 0 8.333 8.333 0 0 0 16.667 0Z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10.202 14.167V10c0-.393 0-.59-.122-.711-.122-.122-.319-.122-.711-.122"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9.993 6.667h.008"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
    ),
    copy:(
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9 15c0-2.828 0-4.243.879-5.121C10.757 9 12.172 9 15 9h1c2.828 0 4.243 0 5.121.879C22 10.757 22 12.172 22 15v1c0 2.828 0 4.243-.879 5.121C20.243 22 18.828 22 16 22h-1c-2.828 0-4.243 0-5.121-.879C9 20.243 9 18.828 9 16v-1Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M17 9c-.003-2.957-.047-4.489-.908-5.538a4 4 0 0 0-.554-.554C14.43 2 12.788 2 9.5 2c-3.287 0-4.931 0-6.038.908a4 4 0 0 0-.554.554C2 4.57 2 6.212 2 9.5c0 3.287 0 4.931.908 6.038a4 4 0 0 0 .554.554c1.05.86 2.58.905 5.538.908"
    />
  </svg>
    )

}

export default Icon