import { title } from 'process'
import React, { useEffect } from 'react'
import PageWrapper from 'UI/PageWrapper'
import entryImg1 from "assets/images/entry-img/3.png";
import entryImg2 from "assets/images/entry-img/4.png";
import entryImg3 from "assets/images/entry-img/5.png";
import placeholderText from 'utils/placeholder-text';
import { useNavigate } from 'react-router-dom';

type Props = {}

const list = [
    {
        title:'Куда поехать?',
        img:entryImg1,
        text:placeholderText
    },
    {
        title:'Активный отдых',
        img:entryImg2,
        text:placeholderText
    },
    {
        title:'Развлечения',
        img:entryImg3,
        text:placeholderText
    },
]

const EntryDesc = (props: Props) => {
  const nav = useNavigate()

  return (
    <PageWrapper style={{padding:'24px 16px'}}>
        <ul className='entry-list' onClick={() => nav('/home')}>
            {list.map(({img,text,title}) => (
                <li>
                    <h2>{title}</h2>
                    <img src={img} alt="" />
                    <p>{text}</p>
                </li>
            ))}
        </ul>
    </PageWrapper>
  )
}

export default EntryDesc