import React from "react";
import Icon from "UI/Icon";
import users from 'assets/images/icons/users.svg'
import stars from 'assets/images/icons/stars.svg'
type Props = {};

const FriendsLines = (props: Props) => {
  return (
    <div className="friends-lines">
      <p style={{fontSize:12}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's.
      </p>
      <div className="fr-table-wrapper glass">
        <div className="fr-table">
          <div className="fr-table-row head">
            <p>Линия</p>
            <p>Процент</p>
            <p>Условия <Icon icon="info"/></p>
          </div>
          {Array.from({length:5}).map((_,i) => (
              <div className="fr-table-row">
                <p>{i+1}</p>
                <p>1% в TON</p>
                <p>От сумм <br /> покупок</p>
              </div>
            ))}
        </div>
      </div>
      <p style={{fontSize:12}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's.
      </p>
      <div className="fr-info-blocks">
            <div className="fr-info-block glass">
              <Icon icon="info"/>
              <img src={users}/>
              <p>100 ENF за каждого приглашенного пользователя</p>
            </div>
            <div className="fr-info-block glass">
              <Icon icon="info"/>
              <img src={stars}/>
              <p>0.1 TON за каждого приглашенного организатора</p>
            </div>
      </div>
    </div>
  );
};

export default FriendsLines;
