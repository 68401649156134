import dayjs from 'dayjs'
import React from 'react'
import Icon from 'UI/Icon'
import formatNumber from 'utils/format-number'

type Props = {}

const FriendsAccurals = (props: Props) => {
  return (
    <div>
      <div className="month-results glass">
        <p>Результат за месяц:</p>
        <div className='results-row'>
          <div>
            <img src="img/enp.png" alt="" />
            <p className='glow'>+ {formatNumber(13210)}</p>
          </div>
          <div>
            <Icon icon='ton'/>
            <p className='glow'>+ {formatNumber(7)}</p>
          </div>
        </div>
      </div>
      <div className="accurals-filters">
          <div>
            <button className='radio-input'/>
            <img src="img/enp.png" alt="" />
          </div>
          <div>
            <button className='radio-input'/>
            <Icon icon='ton'/>
          </div>
        </div>
        <ul className='friends-list accurals-list'>
          {Array.from({length:10}).map((_,i) => {
            const date = dayjs().subtract(i,'day')
            return (
              <li className='glass'>
                <div>{date.format('DD.MM.YY')}</div>
                <div>
                {i%2 ? <Icon icon='ton'/> : <img src="img/enp.png" alt="" />}
                <p className='glow'>+ 200</p>
                </div>
              </li>
            )
          })}
        </ul>
    </div>
  )
}

export default FriendsAccurals