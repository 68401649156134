import React, { useEffect, useRef, useState } from "react";
import PageWrapper from "UI/PageWrapper";
import entryImg1 from "assets/images/entry-img/1.png";
import entryImg2 from "assets/images/entry-img/2.png";
import "./Entry.css";
import Button from "UI/Button";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { regionsOfRussia } from "utils/regions";
type Props = {};

const Entry = (props: Props) => {
  const [stage, setstage] = useState(0);
  const nav = useNavigate();
  const next = (n: number) => {
    const next = stage + n;
    console.log("🚀 ~ next ~ next:", next);
    if (next === 2) nav("/entry-desc");
    else setstage(next);
  };

  return (
    <PageWrapper className="entry-page">
      <AnimatePresence mode="sync">
        <div className="entry-progress-container">
          <motion.div
            className="entry-progress gradient"
            animate={{ x: `${stage * 100}%` }}
          />
        </div>
        <div className="entry-page-wrapper">
          <AnimatePresence mode="wait">
            <motion.div
              key={stage}
              initial={{ x: "110%" }}
              animate={{ x: "0%" }}
              exit={{ x: "-110%" }}
              transition={{ duration: 0.15, ease: "circInOut" }}
              className="entry-page-screen"
            >
              <EntryStage stage={stage} next={() => next(1)} />
            </motion.div>
          </AnimatePresence>
        </div>
      </AnimatePresence>
    </PageWrapper>
  );
};
type EntryStageProps = {
  stage: number;
  next: () => void;
};
const EntryStage = ({ next, stage }: EntryStageProps) => {
  const [query, setquery] = useState("");
  const [opened, setopened] = useState(false);
  const [list, setlist] = useState<string[]>(regionsOfRussia);
  const input = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (!query.length) setlist(regionsOfRussia);
    else
      setlist(
        regionsOfRussia.filter((reg) =>
          reg.toLowerCase().includes(query.toLowerCase())
        )
      );
  }, [query]);
  useEffect(() => {
    setTimeout(() => {
      input.current?.scrollIntoView({behavior:"smooth"})
    }, 400);
  }, [opened])
  switch (stage) {
    case 0:
      return (
        <>
          <h1>Куда поехать?</h1>
          <img src={entryImg1} alt="" />
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
          <Button onClick={next}>Продолжить</Button>
        </>
      );

    case 1:
      return (
        <>
          <h1>Какой регион выберем?</h1>
          <img src={entryImg2} alt="" />
          <div className="entry-input-wrapper gradient">
            <div className="entry-input-wrapper-inner">
              <input
                ref={input}
                onFocus={() => setopened(true)}
                onBlur={() => setopened(false)}
                type="text"
                placeholder="Введите название региона"
                value={query}
                onChange={({ target: { value } }) => setquery(value)}
              />
              <span className="entry-arrow"/>
              <ul className={`entry-select-list ${opened && 'open'}`}>
                {list.map((reg) => (
                  <li key={reg} onMouseDown={() => setquery(reg)}>{reg}</li>
                ))}
              </ul>
            </div>
          </div>
          <Button onClick={next}>Продолжить</Button>
        </>
      );

    default:
      return null;
      break;
  }
};

export default Entry;
