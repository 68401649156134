import { HTMLMotionProps, motion, Variants } from 'framer-motion'
import React from 'react'

interface Props extends HTMLMotionProps<"div"> {

}

const FriendsTab = (props: Props) => {
  const variants:Variants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? '10%' : '-10%',
        opacity: 0
      };
    },
    center: {
      x: 0,
      opacity: 1
    },
    exit: (direction: number) => {
      return {
        position:'absolute',
        width:'100%',
        x: direction < 0 ? '10%' : '-10%',
        opacity: 0
      };
    }
  };
  return (
    <motion.div
    variants={variants}
    initial="enter"
    animate="center"
    exit="exit"
    transition={{ease:'circInOut',duration:0.2}}
    className='earn-page-section'
    {...props}>
        {props.children}
    </motion.div>
  )
}

export default FriendsTab