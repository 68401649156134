import React, { useState } from "react";
import Button from "UI/Button";
import PageWrapper from "UI/PageWrapper";
import UserTopBar from "UI/UserTopBar";
import FriendsLines from "./components/FriendsLines";
import FriendsReferals from "./components/FriendsReferals";
import FriendsAccurals from "./components/FriendsAccurals";
import { AnimatePresence } from "framer-motion";
import FriendsTab from "./components/FriendsTab";
import "./Friends.css";
const tabs = [
  {
    title: "Линии",
    component: <FriendsLines />,
  },
  {
    title: "Рефералы",
    component: <FriendsReferals />,
  },
  {
    title: "Начисления",
    component: <FriendsAccurals />,
  },
];

const Friends = () => {
  const [tab, settab] = useState(tabs[0].title);
  const [direction, setdirection] = useState(0)

  const goToPage = (t:string) => {
    const currentIndex = tabs.findIndex(({title}) => title === tab)
    const nextIndex = tabs.findIndex(({title}) => title === t)
    setdirection(currentIndex > nextIndex ? -1 : 1)
    settab(t)
  }

  const tapElem = tabs.find(({title}) => title === tab)?.component

  return (
    <PageWrapper className="friends-page">
      <UserTopBar />
      <div className="friends-wrapper">
        <h1>Приглашай друзей</h1>
        <div className="tabs-buttons">
          {tabs.map(({ title }) => {
            const isCurrent = tab === title;
            return (
              <Button
              data-non-active={!isCurrent}
                className={isCurrent ? "current" : ""}
                onClick={() => goToPage(title)}
              >
                {title}
              </Button>
            );
          })}
        </div>
        <div className="tabs-wrapper">
        <AnimatePresence mode="sync" custom={direction} initial={false}>
            <FriendsTab custom={direction} key={tab}>
              {tapElem}
            </FriendsTab>
        </AnimatePresence>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Friends;
