import React, { useContext, useEffect, useState } from 'react'
import appContext from 'utils/app-context'
import Icon from './Icon'
import { AnimatePresence, motion } from 'framer-motion'

// Импортируем изображения из assets
import image1 from 'assets/images/entry-img/1.png'
import image2 from 'assets/images/entry-img/2.png'
import image3 from 'assets/images/entry-img/3.png'
import image4 from 'assets/images/entry-img/4.png'
import image5 from 'assets/images/entry-img/5.png'
import image6 from 'assets/images/logo.png'
// добавьте другие изображения по необходимости

type Props = {}

const LoadingScreen = (props: Props) => {
  // const { user ,langs,locale} = useContext(appContext)
  const [mediaLoaded, setMediaLoaded] = useState(false)

  // Массив импортированных изображений
  const media = [image1, image2, image3, image4, image5,image6]

  // Массив изображений по путям
  const media2 = [
    'img/enp.png',
  ]

  // Предзагрузка всех медиа
  useEffect(() => {
    // Функция предзагрузки медиа с отложенным стартом
    const loadMedia = async () => {
      const allMedia = [...media, ...media2];

      const promises = allMedia.map((src) => {
        return new Promise<void>((resolve) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve();
          img.onerror = () => resolve(); // Игнорируем ошибки
        });
      });

      await Promise.all(promises);
      setMediaLoaded(true);
    };

    // Отложенная предзагрузка медиа, чтобы дать время рендерингу анимации
    const delayMediaLoad = () => {
      setTimeout(() => {
        requestAnimationFrame(() => {
          loadMedia();
        });
      }, 0); // Запускаем загрузку через `setTimeout`, чтобы избежать блокировки
    };

    delayMediaLoad();
  }, [media, media2]);

  // Проверяем условие для скрытия экрана загрузки
  const isLoaded = mediaLoaded

  return (
    <AnimatePresence>
      {!isLoaded && (
        <motion.div
          className='loading-screen'
          exit={{ opacity: [1,0] }}
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          transition={{duration:1}}
        >
          {/* Иконка анимации работает независимо от предзагрузки */}
          {/* <Icon icon='loading' /> */}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default LoadingScreen
