import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import routes from 'routes'
import appContext from 'utils/app-context'
import tg from 'utils/tg'

type Props = {}

const Navigation = (props: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {locale} = useContext(appContext)
  const currentLoc = routes.find(({route:{path}}) => location.pathname === path)
  // const titles = locale?.navigationBarTitles
  const titles = true
  if (!currentLoc?.navBarIcon || !titles) return null
  

  return (
    <nav>
      {routes.map(({route:{path},navBarIcon,titleKey}) => {
        if (!navBarIcon || !titleKey) return null
        const handleClick = () => {
          navigate(path || '')
          tg.HapticFeedback.selectionChanged()
        }
        const iscurrentRoute = location.pathname === path
        return (
          <button className={`nav-button ${iscurrentRoute && 'current'}`} key={path} onClick={handleClick}>
        {navBarIcon}
          {/* <p className={`sidebar__text ${iscurrentRoute && 'sidebar__text_first'}`}>{titles[titleKey]}</p> */}
          <p className={`sidebar__text ${iscurrentRoute && 'sidebar__text_first'}`}>{titleKey}</p>
        </button>
        )
      })}
    </nav>
  )
}

export default Navigation