import {
  RouterProvider,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import useAppData from "./hooks/use-app-data";
import routes from "./routes";
import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import tg from "utils/tg";
import Navigation from "UI/Navigation";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import Loading from "pages/Loading/Loading";
import LoadingScreen from "UI/LoadingScreen";
function App() {
  const navigation = useNavigate();
  const { AppContext, AppContextValue } = useAppData();
  const element = useRoutes(routes.map(({ route }) => route));
  const location = useLocation();

  const reloadBackButton = () => {
    if (tg.BackButton.isVisible) return false;
    tg.BackButton.onClick(() => {
      navigation(-1);
    });
    tg.BackButton.show();
  };

  useEffect(() => {
    tg.expand();
    tg.disableVerticalSwipes();
    reloadBackButton();
    tg.onEvent("viewportChanged", reloadBackButton);
    
  }, []);
  if (!element) return null;
  return (
    <TonConnectUIProvider
    manifestUrl={window.location.origin + "/tonconnect-manifest.json"}
  >
    <AppContext.Provider value={AppContextValue}>
      <AnimatePresence mode="sync">
        {React.cloneElement(element, { key: location.pathname })}
      </AnimatePresence>
      <Navigation />
      <LoadingScreen/>
    </AppContext.Provider>
    </TonConnectUIProvider>
  );
}

export default App;
